import React, { FC, useEffect } from 'react';
import Container from '~/components/Container';
import { styles as TextStyes } from '~/components/Text';
import { Identifiers } from '~/constants/enum';
import useCmsBlocks from '~/hooks/useCmsBlocks';
import { replaceClassNames } from '~/utils/converters';
import styles from './Footer.module.scss';

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
    const { cmsBlocks, loading } = useCmsBlocks([Identifiers.Footer, Identifiers.CopyRight]);

    const complieStyle = {
        Footer: styles,
        Text: TextStyes
    };
    const copyRightArea = cmsBlocks[Identifiers.CopyRight]?.content;
    const footerArea = cmsBlocks[Identifiers.Footer]?.content;

    return (
        <Container rootClassName={styles.footer_wrapper} className={styles.footer}>
            {loading || !cmsBlocks ? null : (
                <React.Fragment>
                    {' '}
                    <div
                        dangerouslySetInnerHTML={{
                            __html: replaceClassNames(footerArea, complieStyle)
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: replaceClassNames(copyRightArea, complieStyle)
                        }}
                    />
                </React.Fragment>
            )}
            <div id="footer_ratingbadge"></div>
        </Container>
    );
};

export default Footer;
